import {
  contactFormStep,
  estimateOptions,
  optionsYesNoWithSometimes,
  optionsYesNoWithText,
  paymentForm,
  productFormStep
} from './quiz';

const healthRateOptions = [
  {
    title: 'Excellent',
    step_id: 20,
    id: 79
  },
  {
    title: 'Good',
    step_id: 20,
    id: 80
  },
  {
    title: 'Fair',
    step_id: 20,
    id: 81
  },
  {
    title: 'Poor',
    step_id: 20,
    id: 82
  }
];
const respiratoryOptions = [
  {
    title: 'Asthma',
    step_id: 23,
    id: 83
  },
  {
    title: 'Sleep apnea',
    step_id: 23,
    id: 84
  },
  {
    title: 'Emphysema',
    step_id: 23,
    id: 85
  },
  {
    title: 'Snoring',
    step_id: 23,
    id: 86
  },
  {
    title: 'Chronic bronchitis',
    step_id: 23,
    id: 87
  },
  {
    title: 'Other',
    user_value: true,
    step_id: 23,
    id: 88
  },
  {
    title: 'None of the above',
    step_id: 23,
    id: 15,
    none_selected: true
  }
];
const cardioVascularOptions = [
  {
    title: 'High blood pressure',
    step_id: 24,
    id: 89
  },
  {
    title: 'Heart disease/heart attack',
    step_id: 24,
    id: 90
  },
  {
    title: 'Congestive heart failure',
    step_id: 24,
    id: 91
  },
  {
    title: 'Blood clot',
    step_id: 24,
    id: 92
  },
  {
    title: 'None of the above',
    step_id: 24,
    id: 15,
    none_selected: true
  }
];
const gastrointestinalOptions = [
  {
    title: 'Nausea/vomiting',
    step_id: 25,
    id: 93
  },
  {
    title: 'Abdominal pain',
    step_id: 25,
    id: 94
  },
  {
    title: 'Heartburn/GERD',
    step_id: 25,
    id: 95
  },
  {
    title: 'Belching',
    step_id: 25,
    id: 96
  },
  {
    title: 'Ulcer disease',
    step_id: 25,
    id: 97
  },
  {
    title: 'Colitis',
    step_id: 25,
    id: 98
  },
  {
    title: 'Constipation',
    step_id: 25,
    id: 99
  },
  {
    title: 'Crohn’s',
    step_id: 25,
    id: 100
  },
  {
    title: 'Diarrhea',
    step_id: 25,
    id: 101
  },
  {
    title: 'Gallbladder Disease/Stones',
    step_id: 25,
    id: 102
  },
  {
    title: 'Celiac',
    step_id: 25,
    id: 103
  },
  {
    title: 'IBS',
    step_id: 25,
    id: 104
  },
  {
    title: 'Other',
    user_value: true,
    step_id: 25,
    id: 105
  },
  {
    title: 'None of the above',
    step_id: 25,
    id: 15,
    none_selected: true
  }
];
const genitourinaryOptions = [
  {
    title: 'Difficulty urinating',
    step_id: 26,
    id: 106
  },
  {
    title: 'Urinary incontinence',
    step_id: 26,
    id: 107
  },
  {
    title: 'Recurrent urinary infections',
    step_id: 26,
    id: 108
  },
  {
    title: 'Infertility',
    step_id: 26,
    id: 109
  },
  {
    title: 'Sexual problems',
    step_id: 26,
    id: 110
  },
  {
    title: 'Abnormal menstrual period',
    step_id: 26,
    id: 111
  },
  {
    title: 'Kidney stones',
    step_id: 26,
    id: 112
  },
  {
    title: 'Enlarged prostate',
    step_id: 26,
    id: 113
  },
  {
    title: 'Other',
    user_value: true,
    step_id: 26,
    id: 114
  },
  {
    title: 'None of the above',
    step_id: 26,
    id: 15,
    none_selected: true
  }
];
const musculoskeletalOptions = [
  {
    title: 'Aching muscles/joints',
    step_id: 27,
    id: 115
  },
  {
    title: 'Low back pain',
    step_id: 27,
    id: 116
  },
  {
    title: 'Arthritis',
    step_id: 27,
    id: 117
  },
  {
    title: 'Vertebral Disc Problem',
    step_id: 27,
    id: 118
  },
  {
    title: 'Torn ligaments/muscle soreness',
    step_id: 27,
    id: 119
  },
  {
    title: 'Osteoporosis/osteopenia',
    step_id: 27,
    id: 120
  },
  {
    title: 'Other',
    user_value: true,
    step_id: 27,
    id: 121
  },
  {
    title: 'None of the above',
    step_id: 27,
    id: 15,
    none_selected: true
  }
];
const endocrineOptions = [
  {
    title: 'Diabetes Mellitus',
    step_id: 28,
    id: 122
  },
  {
    title: 'Thyroid disease',
    step_id: 28,
    id: 123
  },
  {
    title: 'Elevated cholesterol',
    step_id: 28,
    id: 124
  },
  {
    title: 'Elevated triglycerides',
    step_id: 28,
    id: 125
  },
  {
    title: 'Gout',
    step_id: 28,
    id: 126
  },
  {
    title: 'Other',
    user_value: true,
    step_id: 28,
    id: 127
  },
  {
    title: 'None of the above',
    step_id: 28,
    id: 15,
    none_selected: true
  }
];
const skinOptions = [
  {
    title: 'Infections (boils, ulcers, etc)',
    step_id: 29,
    id: 128
  },
  {
    title: 'Chronic rashes',
    step_id: 29,
    id: 129
  },
  {
    title: 'Bruises easily',
    step_id: 29,
    id: 130
  },
  {
    title: 'Excessive hair growth (females)',
    step_id: 29,
    id: 131
  },
  {
    title: 'Other',
    user_value: true,
    step_id: 29,
    id: 132
  },
  {
    title: 'None of the above',
    step_id: 29,
    id: 15,
    none_selected: true
  }
];
const otherOptions = [
  {
    title: 'Low energy level',
    step_id: 30,
    id: 133
  },
  {
    title: 'Depression, bipolar, ADD',
    step_id: 30,
    id: 134
  },
  {
    title: 'Anxiety disorder, OCD',
    step_id: 30,
    id: 135
  },
  {
    title: 'Panic attacks',
    step_id: 30,
    id: 136
  },
  {
    title: 'Headache',
    step_id: 30,
    id: 137
  },
  {
    title: 'Psychological/psychiatric care',
    step_id: 30,
    id: 138
  },
  {
    title: 'History of child abuse',
    step_id: 30,
    id: 139
  },
  {
    title: 'History of rape/molestation',
    step_id: 30,
    id: 140
  },
  {
    title: 'History of physical violence',
    step_id: 30,
    id: 141
  },
  {
    title: 'Anemia',
    step_id: 30,
    id: 142
  },
  {
    title: 'Sickle cell disease',
    step_id: 30,
    id: 143
  },
  {
    title: 'Other',
    user_value: true,
    step_id: 30,
    id: 144
  },
  {
    title: 'None of the above',
    step_id: 30,
    id: 15,
    none_selected: true
  }
];
const familyHistoryOptions = [
  {
    title: 'High blood pressure',
    step_id: 31,
    id: 1
  },
  {
    title: 'High cholesterol',
    step_id: 31,
    id: 2
  },
  {
    title: 'Diabetes',
    step_id: 31,
    id: 3
  },
  {
    title: 'Thyroid disease',
    step_id: 31,
    id: 4
  },
  {
    title: 'Obesity',
    step_id: 31,
    id: 5
  },
  {
    title: 'Heart disease',
    step_id: 31,
    id: 6
  },
  {
    title: 'Other',
    user_value: true,
    step_id: 31,
    id: 7
  },
  {
    title: 'None of the above',
    step_id: 31,
    id: 8,
    none_selected: true
  }
];
const smokeOptions = [
  {
    title: 'Never',
    step_id: 38,
    id: 1
  },
  {
    title: 'In the past',
    step_id: 38,
    id: 2
  },
  {
    title: 'Currently',
    step_id: 36,
    id: 3
  }
];
const alcoholOptions = [
  {
    title: 'Yes',
    step_id: 39,
    id: 1
  },
  {
    title: 'No',
    step_id: 40,
    id: 2
  }
];

export function NutritionSteps(
  user: any | null,
  isLoggedIn: boolean,
  isEdit?: boolean
): any[] {
  const steps = [
    contactFormStep,
    productFormStep('Which Nutrition product are you interested in?'),
    {
      id: 3,
      step_id: 4,
      registration_step: true,
      form_id: 3
    },
    {
      title: 'What prompted you to seek dietitian services at this time?',
      id: 4,
      step_edit_start: true,
      step_id: 6,
      form_id: 7,
      required: true
    },
    {
      title: 'What are your personal goals we can help you achieve?',
      id: 6,
      step_id: 7,
      form_id: 7,
      required: true
    },
    {
      title:
        'How confident are you about the amount of current nutrition knowledge you have?',
      id: 7,
      form_id: 12,
      list: estimateOptions(8),
      content: {
        body: '<p class="lighter">(Low 1 2 3 4 5 High)</p>'
      }
    },
    {
      title:
        'How confident are you about your ability to apply the nutrition knowledge you have?',
      id: 8,
      form_id: 12,
      list: estimateOptions(9),
      content: {
        body: '<p class="lighter">(Low 1 2 3 4 5 High)</p>'
      }
    },
    {
      title: 'How ready are you to make lifestyle changes?',
      id: 9,
      form_id: 12,
      list: estimateOptions(10),
      content: {
        body: '<p class="lighter">(Low 1 2 3 4 5 High)</p>'
      }
    },
    {
      title:
        'Do you have any food allergies, sensitivities, or intolerances? Please explain.',
      id: 10,
      form_id: 12,
      list: optionsYesNoWithText(11)
    },
    {
      title:
        'Do you currently follow a special diet or have any dietary restrictions or limitations for any reason (health, cultural, religious, etc)? Please explain.',
      id: 11,
      form_id: 12,
      list: optionsYesNoWithText(12)
    },
    {
      title: 'Have you tried any diets to lose weight? If so, please explain.',
      id: 12,
      form_id: 12,
      list: optionsYesNoWithText(13)
    },
    {
      title: 'Do you eat more rapidly than others?',
      id: 13,
      form_id: 12,
      list: optionsYesNoWithSometimes(14)
    },
    {
      title: 'Do you eat until feeling uncomfortably full?',
      id: 14,
      form_id: 12,
      list: optionsYesNoWithSometimes(15)
    },
    {
      title:
        'Do you eat large amounts of food when you are not feeling physically hungry?',
      id: 15,
      form_id: 12,
      list: optionsYesNoWithSometimes(16)
    },
    {
      title:
        'Do you eat alone because of being embarrassed by how much you eat?',
      id: 16,
      form_id: 12,
      list: optionsYesNoWithSometimes(17)
    },
    {
      title: 'Do you feel disgusted, depressed, or guilty after overeating?',
      id: 17,
      form_id: 12,
      list: optionsYesNoWithSometimes(18)
    },
    {
      title: 'Do you feel that you cannot control the amounts you are eating?',
      id: 18,
      form_id: 12,
      list: optionsYesNoWithSometimes(19)
    },
    {
      title: 'How would you rate your health? ',
      id: 19,
      form_id: 12,
      list: healthRateOptions
    },
    {
      title: null,
      id: 20,
      step_id: 21,
      not_selectable: true,
      form_id: 1
    },
    {
      title: 'Have you had any recent weight changes? If so, please explain.',
      id: 21,
      form_id: 12,
      list: optionsYesNoWithText(22)
    },
    {
      title: 'Respiratory',
      id: 22,
      step_id: 23,
      list: respiratoryOptions,
      form_id: 6
    },
    {
      title: 'Cardiovascular',
      id: 23,
      step_id: 24,
      list: cardioVascularOptions,
      form_id: 6
    },
    {
      title: 'Gastrointestinal',
      id: 24,
      step_id: 25,
      list: gastrointestinalOptions,
      form_id: 6
    },
    {
      title: 'Genitourinary',
      id: 25,
      step_id: 26,
      list: genitourinaryOptions,
      form_id: 6
    },
    {
      title: 'Musculoskeletal',
      id: 26,
      step_id: 27,
      list: musculoskeletalOptions,
      form_id: 6
    },
    {
      title: 'Endocrine',
      id: 27,
      step_id: 28,
      list: endocrineOptions,
      form_id: 6
    },
    {
      title: 'Skin',
      id: 28,
      step_id: 29,
      list: skinOptions,
      form_id: 6
    },
    {
      title: 'Other',
      id: 29,
      step_id: 30,
      list: otherOptions,
      form_id: 6
    },
    {
      title: 'Do you have a family history of the following?',
      id: 30,
      step_id: 31,
      list: familyHistoryOptions,
      form_id: 6
    },
    {
      title: 'List history of surgeries',
      id: 31,
      step_id: 32,
      form_id: 7
    },
    {
      title: 'List current medications and dosages',
      id: 32,
      step_id: 33,
      form_id: 7
    },
    {
      title: 'List current vitamins/supplements',
      id: 33,
      step_id: 50,
      form_id: 7
    },
    {
      title: 'Do you smoke?',
      id: 50,
      form_id: 12,
      list: smokeOptions
    },
    {
      title: 'How long?',
      id: 36,
      step_id: 37,
      form_id: 7
    },
    {
      title: 'How many packs per day?',
      id: 37,
      step_id: 38,
      form_id: 7
    },
    {
      title: 'Do you drink alcohol?',
      id: 38,
      form_id: 12,
      list: alcoholOptions
    },
    {
      title: 'How many drinks per week?',
      id: 39,
      step_id: 40,
      form_id: 7
    },
    {
      title: 'Average hours of sleep each night?',
      id: 40,
      step_id: 41,
      form_id: 7
    },
    {
      title: 'Is your sleep restful?',
      id: 41,
      form_id: 12,
      list: optionsYesNoWithText(42, false)
    },
    {
      title: 'How would you rate your stress level?',
      id: 42,
      form_id: 12,
      list: estimateOptions(43),
      content: {
        body: '<p class="lighter">(Low 1 2 3 4 5 High)</p>'
      }
    },
    {
      title: 'How do you cope with daily stressors?',
      id: 43,
      step_id: 44,
      form_id: 7
    },
    {
      title: 'Any other information',
      id: 44,
      step_id: 45,
      end_step: isEdit,
      form_id: 7
    },
    {
      title: 'Your Intake Form is Complete',
      id: 45,
      step_id: 46,
      not_selectable: true,
      content: {
        body: `
        <div class="circle-img">
          <div class="bg" style="background-image: url(assets/images/placeholder-quiz.jpg)"></div>
        </div>
        <p>You’re almost done!</p>
        <h5>FINAL STEPS</h5>
        <ul>
          <li>
            <img src="assets/icons/checked.svg">
            <p>Schedule Telehealth Consult</p>
          </li>
          <li>
            <img src="assets/icons/checked.svg">
            <p>Make Payment</p>
          </li>
        </ul>
          `
      }
    },
    {
      title: null,
      id: 46,
      step_id: 47,
      checkout_step: true,
      form_id: 9
    },
    {
      title: null,
      id: 47,
      step_id: 48,
      not_selectable: true,
      form_id: 15
    },
    paymentForm(48)
  ];
  return steps;
}
