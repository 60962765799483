import { PropList } from '../../../core/models/models';
import { FitnesSteps } from './fitness.quiz';
import { NutritionSteps } from './nutrition.quiz';
import {
  FollowUpSteps,
  NoQuizProduct,
  SemaglutideTirzepatideSteps
} from './weightloss.quiz';

export const SEM_12_KICKSTART_MED_ID = '7VotoD7xG9LVD0w89GJUrJ3YSLsJMxpn';
export const TER_12_KICKSTART_MED_ID = 'LjO52Yr77oAadftadDs28vbYKvb7LJXn';

export const registrationFields = [
  'first_name',
  'last_name',
  'middle_name',
  'email',
  'dob',
  'sex',
  'phone',
  'id',
  'temp_token',
  'email_verified',
  'phone_verified',
  'relationship_list',
  'role',
  'zip_code',
  'address_1',
  'address_2',
  'age',
  'appeal',
  'city',
  'marital_status',
  'occupation',
  'state',
  'photo_id_exists',
  'photo_exists'
];

export function getQuizType(): PropList {
  return {
    1: { text: 'Weight loss', title: 'Semaglutide/Tirzepatide' },
    2: {
      text: 'Weight loss',
      title: 'Sermorelin'
    },
    3: {
      text: 'Nutrition'
    },
    4: {
      text: 'Fitness'
    },
    5: {
      text: 'Follow Up'
    }
  };
}

export const contactFormStep = {
  id: 1,
  step_id: 2,
  registration_step: true,
  form_id: 4
};

export function productFormStep(title: string) {
  return {
    title,
    id: 2,
    step_id: 3,
    not_selectable: true,
    no_content: true,
    form_id: 8
  };
}

export const addressStep = {
  id: 3,
  step_id: 4,
  registration_step: true,
  form_id: 3
};

export const completeMedicalStep = {
  title: 'Please Complete Your Medical Intake Form',
  id: 4,
  step_id: 5,
  not_selectable: true,
  content: {
    body: `
        <div class="circle-img">
          <div class="bg" style="background-image: url(assets/images/placeholder-quiz.jpg)"></div>
        </div>
        <p>Please answer some simple medical questions for your medical provider to review</p>
          `
  }
};
export function paymentForm(step: number) {
  return {
    no_content: true,
    id: step,
    step_id: null,
    checkout_step: true,
    end_step: true,
    form_id: 11
  };
}
export function optionsYesNoWithText(nextStep: number, reason = true) {
  return [
    {
      title: 'Yes',
      step_id: nextStep,
      id: 1,
      user_value: reason
    },
    {
      title: 'No',
      step_id: nextStep,
      id: 2
    }
  ];
}
export function optionsYesNoWithSometimes(nextStep: number) {
  return [
    {
      title: 'Yes',
      step_id: nextStep,
      id: 1
    },
    {
      title: 'No',
      step_id: nextStep,
      id: 2
    },
    {
      title: 'Sometimes',
      step_id: nextStep,
      id: 3
    }
  ];
}
export function estimateOptions(nextStep: number) {
  return [
    {
      title: '1',
      step_id: nextStep,
      id: 1
    },
    {
      title: '2',
      step_id: nextStep,
      id: 2
    },
    {
      title: '3',
      step_id: nextStep,
      id: 3
    },
    {
      title: '4',
      step_id: nextStep,
      id: 4
    },
    {
      title: '5',
      step_id: nextStep,
      id: 5
    }
  ];
}

export function quizTypes(
  product: any,
  user: any,
  type: number,
  isLoggedIn: boolean,
  isEdit?: boolean
): any {
  switch (type) {
    case 1:
      return SemaglutideTirzepatideSteps(user, isEdit, product);
    case 3:
      return NutritionSteps(user, isLoggedIn, isEdit);
    case 4:
      return FitnesSteps(user, isLoggedIn, isEdit);
    case 5:
      return FollowUpSteps(product);
    default:
      return NoQuizProduct(isLoggedIn);
  }
}

const proggressSteps = [];

function reqistrationStep(
  activeStep: number,
  type: number,
  withProgram: boolean
) {
  if (type === 1) {
    if (withProgram) {
      return [
        {
          id: 1,
          title: 'Sign up',
          completed: activeStep > 1 ? true : false,
          active: activeStep === 1,
          prev_step: 1,
          current_step: 1
        },
        {
          id: 2,
          title: 'Select product',
          completed: activeStep > 2 ? true : false,
          active: activeStep === 2,
          prev_step: 1,
          current_step: 2
        },
        {
          id: 3,
          title: 'Select plan',
          completed: activeStep > 3 ? true : false,
          active: activeStep === 3,
          prev_step: 3,
          current_step: 101
        },
        {
          id: 4,
          title: 'Select options',
          completed: activeStep > 4 ? true : false,
          active: activeStep === 4,
          prev_step: 3,
          current_step: 300
        },
        {
          id: 5,
          title: 'Intake form',
          completed: activeStep > 5 ? true : false,
          active: activeStep === 5,
          prev_step: 4,
          current_step: 4
        }
      ];
    } else {
      return [
        {
          id: 1,
          title: 'Sign up',
          completed: activeStep > 1 ? true : false,
          active: activeStep === 1,
          prev_step: 1,
          current_step: 1
        },
        {
          id: 2,
          title: 'Select product',
          completed: activeStep > 2 ? true : false,
          active: activeStep === 2,
          prev_step: 1,
          current_step: 2
        },
        {
          id: 3,
          title: 'Select plan',
          completed: activeStep > 3 ? true : false,
          active: activeStep === 3,
          prev_step: 3,
          current_step: 101
        },
        {
          id: 5,
          title: 'Intake form',
          completed: activeStep > 5 ? true : false,
          active: activeStep === 5,
          prev_step: 4,
          current_step: 4
        }
      ];
    }
  } else {
    return [
      {
        id: 1,
        title: 'Sign up',
        completed: activeStep > 1 ? true : false,
        active: activeStep === 1,
        prev_step: 1,
        current_step: 1
      },
      {
        id: 2,
        title: 'Select product',
        completed: activeStep > 2 ? true : false,
        active: activeStep === 2,
        prev_step: 1,
        current_step: 2
      },
      {
        id: 3,
        title: 'Intake form',
        completed: activeStep > 3 ? true : false,
        active: activeStep === 3,
        prev_step: 2,
        current_step: 3
      }
    ];
  }
}

function registrationStepSales(activeStep: number) {
  return [
    {
      id: 100,
      title: 'Sign up',
      completed: true,
      active: false,
      current_step: 100
    },
    {
      id: 3,
      title: 'Verify',
      active: true,
      prev_step: 100,
      current_step: 3
    }
  ];
}

export function getReqistrationStep(
  type: number,
  activeStep: number,
  withProgram: boolean,
  isSaleRegistered?: boolean
) {
  if (isSaleRegistered) {
    return registrationStepSales(activeStep);
  } else {
    return reqistrationStep(activeStep, type, withProgram);
  }
}

const planPrice = {
  price: null,
  price_sale: null,
  description: null,
  twelve_week_price: null
};

export const productPlans = [
  {
    title: 'Monthly Subscription',
    subscription: true,
    price: planPrice,
    step_id: 3,
    btn_text: 'Select Plan',
    description:
      'Monthly subscription that auto-renews every 4 weeks. You can cancel at any time and will only pay for the medication that has been shipped.',
    id: 1
  },
  {
    title: '12 Week Options',
    subscription: false,
    price: planPrice,
    step_id: 300,
    btn_text: 'Select Options',
    description:
      'Pre-Pay and save big. Auto-renews to monthly subscription after initial 12 weeks. Select your dosage options next',
    id: 2
  }
];
