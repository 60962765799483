import { contactFormStep, paymentForm, productFormStep } from './quiz';

export function FitnessLevel(points: number): any {
  if (points > 15 && points < 27) {
    return { points: points, text: 'Beginner' };
  } else if (points > 26 && points < 39) {
    return { points: points, text: 'Intermediate' };
  } else if (points > 38 && points < 49) {
    return { points: points, text: 'Advanced' };
  } else {
    return '';
  }
}

export function FitnesSteps(
  user: any | null,
  isLoggedIn: boolean,
  isEdit?: boolean
): any[] {
  const steps = [
    contactFormStep,
    productFormStep('Which Fitness product are you interested in?'),
    {
      id: 3,
      step_id: 4,
      registration_step: true,
      form_id: 3
    },
    {
      title:
        'How many times a week do you currently exercise (You set aside time to complete physical activity)?',
      id: 4,
      step_edit_start: true,
      step_id: 5,
      list: [
        {
          id: 1,
          title: '0-1 days a week',
          point: 1
        },
        {
          id: 2,
          title: '2-3 days a week',
          point: 2
        },
        {
          id: 3,
          title: '4 or more days a week',
          point: 3
        }
      ],
      form_id: 12
    },
    {
      title: 'How often do you incorporate weight training into your exercise?',
      id: 5,
      step_edit_start: true,
      step_id: 6,
      list: [
        {
          id: 1,
          title: '0-1 days a week',
          point: 1
        },
        {
          id: 2,
          title: '2-3 days a week',
          point: 2
        },
        {
          id: 3,
          title: '4 or more days a week',
          point: 3
        }
      ],
      form_id: 12
    },
    {
      title:
        'How many days do you complete cardiovascular activity (brisk walk, running, elliptical, cycling, etc)',
      id: 6,
      step_id: 7,
      list: [
        {
          id: 1,
          title: '0-1 days a week',
          point: 1
        },
        {
          id: 2,
          title: '2-3 days a week',
          point: 2
        },
        {
          id: 3,
          title: '4 or more days a week',
          point: 3
        }
      ],
      form_id: 12
    },
    {
      title: 'What is your endurance level during a mile run?',
      id: 7,
      step_id: 8,
      list: [
        {
          id: 1,
          title: 'can walk a mile',
          point: 1
        },
        {
          id: 2,
          title: 'can jog a mile',
          point: 2
        },
        {
          id: 3,
          title: 'can run more than a mile',
          point: 3
        }
      ],
      form_id: 12
    },
    {
      title: 'How do you classify your daily activity?',
      id: 8,
      step_id: 9,
      list: [
        {
          id: 1,
          title: 'Sedentary (sit at a desk all day)',
          point: 1
        },
        {
          id: 2,
          title: 'Moderate (sitting and moving evenly)',
          point: 2
        },
        {
          id: 3,
          title: 'Active (up on feet, moving all day)',
          point: 3
        }
      ],
      form_id: 12
    },
    {
      title: 'How much water do you consume daily?',
      id: 9,
      step_id: 10,
      list: [
        {
          id: 1,
          title: '24-32 oz (3-4 cups)',
          point: 1
        },
        {
          id: 2,
          title: '40-56 oz (5-7cups)',
          point: 2
        },
        {
          id: 3,
          title: '64-88 oz (8-11 cups)',
          point: 3
        }
      ],
      form_id: 12
    },
    {
      title: 'How many average hours of sleep do you get per night?',
      id: 10,
      step_id: 11,
      list: [
        {
          id: 1,
          title: '2-3',
          point: 1
        },
        {
          id: 2,
          title: '4-6',
          point: 2
        },
        {
          id: 3,
          title: '7 hours or more',
          point: 3
        }
      ],
      form_id: 12
    },
    {
      title:
        'How would you classify the activity of walking up more than flight of stairs?',
      id: 11,
      step_id: 12,
      list: [
        {
          id: 1,
          title: 'out of breath',
          point: 1
        },
        {
          id: 2,
          title: 'Moderately out of breath',
          point: 2
        },
        {
          id: 3,
          title: 'no problem all at',
          point: 3
        }
      ],
      form_id: 12
    },
    {
      title: 'How would you rate your flexibility?',
      id: 12,
      step_id: 13,
      list: [
        {
          id: 1,
          title: 'not flexible/ can’t touch toes',
          point: 1
        },
        {
          id: 2,
          title: 'moderately flexible/ can get right below knees',
          point: 2
        },
        {
          id: 3,
          title: 'extremely flexible/ can touch toes',
          point: 3
        }
      ],
      form_id: 12
    },
    {
      title: 'What is your main fitness goal?',
      id: 13,
      step_id: 14,
      list: [
        {
          id: 1,
          title: 'Lose weight',
          point: 1
        },
        {
          id: 2,
          title: 'Healthy lifestyle',
          point: 2
        },
        {
          id: 3,
          title: 'Gain muscle and become lean',
          point: 3
        }
      ],
      form_id: 12
    },
    {
      title: 'How many pull-ups can you complete?',
      id: 14,
      step_id: 15,
      list: [
        {
          id: 1,
          title: '0-1',
          point: 1
        },
        {
          id: 2,
          title: '2-4',
          point: 2
        },
        {
          id: 3,
          title: 'more than 4',
          point: 3
        }
      ],
      form_id: 12
    },
    {
      title: 'About how many squat jumps can you complete in 1 minute?',
      id: 15,
      step_id: 16,
      list: [
        {
          id: 1,
          title: 'Less than 20',
          point: 1
        },
        {
          id: 2,
          title: '20-40',
          point: 2
        },
        {
          id: 3,
          title: 'More than 40',
          point: 3
        }
      ],
      form_id: 12
    },
    {
      title: 'About how many push ups can you complete in 1 minute?',
      id: 16,
      step_id: 17,
      list: [
        {
          id: 1,
          title: 'Less than 15',
          point: 1
        },
        {
          id: 2,
          title: '16-24',
          point: 2
        },
        {
          id: 3,
          title: 'More than 25',
          point: 3
        }
      ],
      form_id: 12
    },
    {
      title: 'About how long can you hold the plank position?',
      id: 17,
      step_id: 18,
      list: [
        {
          id: 1,
          title: 'Less than 60s',
          point: 1
        },
        {
          id: 2,
          title: '60s-120s',
          point: 2
        },
        {
          id: 3,
          title: 'More than 120s',
          point: 3
        }
      ],
      form_id: 12
    },
    {
      title: 'About how many burpees can you complete in 1 minute?',
      id: 18,
      step_id: 19,
      list: [
        {
          id: 1,
          title: 'Less than 20',
          point: 1
        },
        {
          id: 2,
          title: '20-30',
          point: 2
        },
        {
          id: 3,
          title: 'More than 30',
          point: 3
        }
      ],
      form_id: 12
    },
    {
      title: 'About how many jumping jacks can you complete in 1 minute?',
      id: 19,
      step_id: 20,
      end_step: isEdit,
      list: [
        {
          id: 1,
          title: 'Less than 30',
          point: 1
        },
        {
          id: 2,
          title: '30-60',
          point: 2
        },
        {
          id: 3,
          title: 'More than 60',
          point: 3
        }
      ],
      form_id: 12
    },
    {
      title: "Congratulations! You've Unlocked Your Fitness Level!",
      id: 20,
      step_id: 21,
      not_selectable: true,
      form_id: 20
    },
    {
      title: null,
      id: 21,
      step_id: 22,
      not_selectable: true,
      form_id: 15
    },
    paymentForm(22)
  ];
  return steps;
}
